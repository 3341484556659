import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',[(_vm.item.link)?_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.item.link()}}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({attrs:{"id":"project-path"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.item.title.length > _vm.maxLength ? ((_vm.item.title.slice(0,Math.floor(_vm.maxLength/2))) + "..." + (_vm.item.title.slice(-Math.ceil(_vm.maxLength/2)))) : _vm.item.title))])]}}],null,false,1507340809)},[_c('span',[_vm._v(_vm._s(_vm.item.title))])])],1):_vm._e(),(_vm.item.href)?_c(VBtn,{attrs:{"text":"","to":_vm.item.href()}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({attrs:{"id":"project-path"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.item.title.length > _vm.maxLength ? ((_vm.item.title.slice(0,Math.floor(_vm.maxLength/2))) + "..." + (_vm.item.title.slice(-Math.ceil(_vm.maxLength/2)))) : _vm.item.title))])]}}],null,false,1507340809)},[_c('span',[_vm._v(_vm._s(_vm.item.title))])])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }