<template>
    <div v-if="item">
        <v-btn text v-if="item.link"
            @click="item.link()">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                        id="project-path">{{ item.title.length > maxLength
                        ? `${item.title.slice(0,Math.floor(maxLength/2))}...${item.title.slice(-Math.ceil(maxLength/2))}`
                        : item.title }}</span>
                </template>
                <span >{{ item.title }}</span>
            </v-tooltip>
        </v-btn>
        <v-btn text v-if="item.href"
            :to="item.href()">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                        id="project-path">{{ item.title.length > maxLength
                        ? `${item.title.slice(0,Math.floor(maxLength/2))}...${item.title.slice(-Math.ceil(maxLength/2))}`
                        : item.title }}</span>
                </template>
                <span >{{ item.title }}</span>
            </v-tooltip>
        </v-btn>
    </div>
</template>


<script>
// const moment = require('moment-timezone')

export default {
    props: {
        id: Number,
        getItem: Function,
    },

    components: {
    },

    data() {
        return {
            item: null,
            maxLength: 18,
        }
    },

    created() {
        this.getItem(this.id)
            .then(result => {
                this.item = result
            })
    },

    watch: {
        id(val) {
            this.getItem(val)
                .then(result => {
                    if (val === this.id) {
                        this.item = result
                    }
                })
        },
    },
}
</script>
